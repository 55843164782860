import styled from "styled-components";

export const Content = styled.div`
  grid-column: 2 / -2;
  padding: var(--size-5) 0;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(5 1fr);
    grid-row-gap: var(--size-7);
    padding: var(--size-9) 0;
  }

  @media (min-widt: 2560px) {
    padding: var(--size-9) 0;
  }
`;

export const Preamble = styled.h1`
  font-size: var(--font-size-md);
  grid-column: 1 / 5;
  justify-self: center;
  margin: 0;
  max-width: 65ch;
  text-align: center;

  @media (max-width: 1023px) {
    & br {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 2560px) {
    font-size: var(--font-size-x2l);
  }
`;

export const Paragraph = styled.p<{ position: number }>`
  font-size: var(--font-size-xs);
  hyphens: auto;
  line-height: 1.4;
  max-width: 70ch;
  text-align: justify;

  @media (min-width: 1024px) {
    font-size: var(--font-size-sm);
    grid-column: ${({ position }) => (position % 2 === 0 ? "2 / 5" : "1 / 4")};
    hyphens: none;
    justify-self: center;
    line-height: 1.6;
    margin: 0;
    text-align: left;
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-md);
  }
`;
