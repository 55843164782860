import styled from "styled-components";
import { linkStyle } from "../../common/Link/styles";

export const Container = styled.div`
  grid-column: 2 / -2;
  padding-top: var(--size-8);

  @media (min-width: 1024px) {
    padding-left: var(--size-8);
  }

  @media (min-width: 1300px) {
    padding-top: var(--size-10);
  }
`;

export const Preamble = styled.h1`
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin: 0;

  @media (min-width: 768px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-x2l);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-x3l);
  }
`;

export const Message = styled.div`
  font-size: var(--font-size-xs);
  padding: var(--size-2) 0;

  & p:nth-child(2) {
    padding-left: var(--size-6);
  }

  @media (min-width: 768px) {
    & p:nth-child(2) {
      padding-left: 0;
      text-align: center;
    }
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-sm);
    padding-left: var(--size-8);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-md);
  }
`;

export const Email = styled.span`
  display: inline-block;

  & a {
    ${linkStyle}
  }
`;
