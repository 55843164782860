import React from "react";
import PropTypes from "prop-types";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Blurb, Container, Greeting, Photo, PhotoContainer } from "./styles";
import { IDangerouslySetInnerHtml } from "../../../types/global";

interface IIntroProps {
  photo: IGatsbyImageData;
  blurb: IDangerouslySetInnerHtml;
  greeting: IDangerouslySetInnerHtml;
}

function Intro({ photo, blurb, greeting }: IIntroProps): JSX.Element {
  return (
    <Container>
      <PhotoContainer>
        <Photo image={photo} loading="eager" alt="That's me!" />
      </PhotoContainer>
      <Greeting dangerouslySetInnerHTML={greeting} />
      <Blurb dangerouslySetInnerHTML={blurb} />
    </Container>
  );
}

Intro.propTypes = {
  photo: PropTypes.shape({}).isRequired,
  blurb: PropTypes.shape({
    __html: PropTypes.string.isRequired,
  }).isRequired,
  greeting: PropTypes.shape({
    __html: PropTypes.string.isRequired,
  }).isRequired,
};

export default Intro;
