import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const Container = styled.div`
  display: grid;
  grid-column: 2/-2;
  grid-column-gap: var(--size-5);
  grid-template-areas:
    "greeting  greeting  greeting"
    " blurb     blurb     blurb  "
    " photo     photo     photo  ";
  grid-template-columns: repeat(3, 4fr);
  justify-items: center;
  padding: var(--size-6) 0 var(--size-7) 0;

  @media (min-width: 768px) {
    grid-template-areas:
      "photo greeting"
      "photo greeting"
      "photo blurb  ";
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1440px) {
    grid-template-areas:
      "photo  .  greeting  . "
      "photo  .  greeting  . "
      "photo  .   blurb    . ";
    grid-template-columns: 3fr var(--size-5) 4fr 1fr;
  }

  @media (min-width: 1024px) {
    padding: var(--size-9) 0;
  }
`;

export const Greeting = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xs);
  grid-area: greeting;
  margin: 0;
  padding-bottom: var(--size-4);
  width: 100%;

  @media (min-width: 375px) {
    font-size: var(--font-size-sm);
  }

  @media (min-width: 600px) {
    font-size: var(--font-size-md);
  }

  @media (min-width: 768px) {
    font-size: var(--font-size-lg);
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-xl);
  }

  & h1 {
    margin: 0;

    &:first-child {
      @media (min-width: 1300px) {
        flex-direction: row;
      }
    }

    &:last-child {
      margin-left: auto;
      white-space: nowrap;

      @media (min-width: 1300px) {
        margin-left: initial;
      }
    }
  }
`;

export const Blurb = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: var(--font-size-xs);
  grid-area: blurb;
  padding-bottom: var(--size-5);
  width: 100%;

  @media (min-width: 425px) {
    font-size: var(--font-size-md);
  }

  @media (min-width: 600px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-x2l);
  }

  @media (min-width: 1440px) {
    font-size: var(--font-size-x3l);
  }

  & > span:first-child {
    padding-right: var(--size-1);
  }
`;

export const PhotoContainer = styled.div`
  display: flex;
  grid-area: photo;
  width: 100%;
  height: auto;
`;

export const Photo = styled(GatsbyImage)`
  border: 7px solid white;
  border-radius: 50%;
  box-shadow: var(--shadow-lg);
  height: 50vmin !important;
  margin: 0 auto;
  max-height: 85vw;
  max-width: 85vw;
  min-height: 200px;
  min-width: 200px;
  width: 50vmin !important;
  z-index: -1;

  @media (min-width: 425px) {
    max-width: 44vmin;
    max-height: 45vmin;
  }

  @media (min-width: 768px) {
    margin: auto 0;
    max-width: 40vmin;
    max-height: 40vmin;
  }

  @media (min-width: 768px) {
    border: 10px solid white;
    max-width: 30vw;
    max-height: 30vw;
  }

  @media (min-width: 1300px) {
    margin-left: auto;

    max-width: 400px;
    max-height: 400px;
  }
`;
