import styled, { css } from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(60%, 10fr) 1fr;
  grid-template-rows: repeat(auto-fill, minmax(var(--size-10), 1fr));
  padding: var(--size-7) 0;

  @media (min-width: 2560px) {
    grid-template-columns:
      minmax(var(--size-9), 1fr)
      auto
      minmax(var(--size-9), 1fr);
  }
`;

const separator = css`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns:
    minmax(var(--size-9), 1fr)
    auto
    minmax(var(--size-9), 1fr);
  height: 5rem;
  z-index: -1;

  @media (min-width: 2560px) {
    & div {
      height: 100%;
    }

    & div:first-child {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) calc(100% - 1000px),
        rgba(255, 255, 255, 0) 100%
      );
      grid-column: 1 / 2;
    }

    & div:last-child {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 1000px
      );
      grid-column: 3 / 4;
    }
  }

  @media (min-width: 768px) {
    height: 7rem;
  }
`;

export const SquiggleSeparator = styled.div`
  ${separator}
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff544c' fill-opacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  height: 4.9rem;

  @media (min-width: 768px) {
    height: 6.5rem;
  }
`;

export const CirclesSeparator = styled.div`
  ${separator}
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff544c' fill-opacity='1'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`;

export const TicTacToeSeparator = styled.div`
  ${separator}
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23ff544c' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
`;
