import React from "react";
import Obfuscate from "react-obfuscate";
import Link from "../../common/Link";
import { Container, Preamble, Message, Email } from "./styles";
import social from "../../../data/social";

function Contact(): JSX.Element {
  const twitter = social.filter((account) => account.name === "Twitter")[0];

  return (
    <Container>
      <Preamble>Get in touch!</Preamble>
      <Message>
        <p>
          If you want to work with me, ask about a project, or simply say hi, my
          inbox is always open!
        </p>
        <p>
          You can get in touch at{" "}
          <Email>
            <Obfuscate
              email="christian@fratta.dev"
              headers={{
                subject: "Hello!",
              }}
            />
          </Email>
          , or on <Link to={twitter.link}>Twitter</Link>!
        </p>
      </Message>
    </Container>
  );
}

export default Contact;
