import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Nav from "../components/common/Nav";
import {
  Grid,
  SquiggleSeparator,
  TicTacToeSeparator,
} from "../components/common/Grid";
import Footer from "../components/common/Footer";
import Emoji from "../components/common/Emoji";
import Contact from "../components/home/Contact";
import Intro from "../components/home/Intro";
import { Content, Preamble, Paragraph } from "../components/about/styles";

function Landing(): JSX.Element {
  const photo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "christian/eclipse.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" description="About me!" />
      <Nav />
      <Grid>
        <Intro
          photo={photo.file.childImageSharp.gatsbyImageData}
          greeting={{
            __html: `<h1>Hello!</h1>
          <h1>I'm Christian!</h1>`,
          }}
          blurb={{
            __html: `
          <span>I&apos;m a full-time person</span>
          <span>
            who loves to <b>think</b> & <b>do</b>
          </span>`,
          }}
        />
        <SquiggleSeparator>
          <div />
          <div />
        </SquiggleSeparator>
        <Content>
          <Preamble>
            Let me get straight to the juicy bits <br />
            without wasting your time <Emoji label="grin" icon="😄" />
          </Preamble>
          <Paragraph position={1}>
            As a developer,{" "}
            <b>
              I take ownership of my work and I&apos;m very objective-driven
            </b>
            . I always worked for small startups where every decision is a
            tradeoff, an attitude which became an important counterweight to my
            perfectionist tendencies. I love the sensation of having implemented
            something complex in a maintainable, readable, and simple way (in
            that order), but my main priorities are always the short- and
            long-term health of my organization.
          </Paragraph>
          <Paragraph position={2}>
            As a professional,{" "}
            <b>my colleagues&apos; well-being is an important concern</b>. I
            understand what it&apos;s like to work in small teams (3 to 6
            people) where everybody must pull their weight, and the variety of
            effects it can have on different people. Automation is one of my
            favourite disciplines specifically because it takes away busywork
            from everybody else and leaves them free to focus on what{" "}
            <i>they</i> find important.
          </Paragraph>
          <Paragraph position={3}>
            As a person,{" "}
            <b>I am very focused on expanding inward and outward</b>. There are
            few things I relish more than mastering a new skill, whether
            it&apos;s a new language (both as in &quot;German&quot; and as in
            &quot;Python&quot;), an instrument, or a different way of looking at
            the world.
          </Paragraph>
          <Paragraph position={4}>
            Lastly, <b>I&apos;m quite affable and a good team player</b>, but
            I&apos;ll let you be the judge of that during the interviewing
            process should you decide to reach out{" "}
            <Emoji label="smile" icon="🙂" />
          </Paragraph>
        </Content>
        <TicTacToeSeparator>
          <div />
          <div />
        </TicTacToeSeparator>
        <Contact />
      </Grid>
      <Footer />
    </Layout>
  );
}

export default Landing;
